<template>

  <div class="main">

    <div class="hero-wrapper">

      <div class="hero-glow">

        <!-- <div class="stars"></div> -->

        <div class="hero-header">
          <ul>
            <!-- <li class="hide479"><a href="https://www.frdc.com.au/" target="_blank"><img src="../assets/img/logo-frdc.png" alt="frdc" /></a></li> -->
            <li class="hide479"><a href="http://www.sesafe.com.au/" target="_blank"><img src="../assets/img/logo-sesafe.png" alt="sesafe" /></a></li>
            <li class="hide479"><a href="http://fishsafeaustralia.com.au/" target="_blank"><img src="../assets/img/logo-fishsafe.png" alt="fish safe" /></a></li>
          </ul>
          <router-link to="/" class="home-icon"><font-awesome-icon icon="home" /></router-link>
        </div>

        <div class="hero-content">
          <h4>Our Weekly</h4>
          <h1>Prize Draw</h1>

          <h5>Prizes donated by our sponsors and the weekly winners results</h5>

          <div class="spacer s20"></div>


          <!-- <div class="hero-form">
            <div class="buttons">
              <a href="#sec-signup" v-smooth-scroll class="button is-primary">Count me in</a>
              <a href="#sec-wall" v-smooth-scroll class="button is-white">See the Industry Wall</a>
            </div>
          </div> -->

        </div>

        <!-- <div class="ivan"></div> -->
        
      </div>

      <div class="curved-border"></div>
      
    </div>

    <div id="sec-wall">

      <h2>Seafood Leaders Prize Pool</h2>
      <h5>View some of the weekly prizes being offered by our industry sponsors:</h5>

    </div>



    <div class="resources-wrapper">

      <div class="spacer s20"></div>
      
      <div class="resource" v-for="(item, index) in resources" :key="index">
        
          <!-- <div class="image"><a :href="item.url" target="_blank"><img :src="require(`../assets/img/logos/${item.image}`)" :alt="item.title" /></a></div> -->
          <div class="content">            
            <h4><span><font-awesome-icon icon="star" /></span>&nbsp;{{item.title}}</h4>
            <div class="details">{{item.details}}</div>
            <div class="url">Sponsored by: <span><a :href="item.url">{{item.sponsor}}</a></span></div>            
          </div>
      </div>

    </div>


    <!-- overlay -->
    <div class="overlay" v-if="showModal" @click="showModal = false">
      <div class="modal" v-if="showModal">
        <button class="close" @click="showModal = false">x</button>
        <h3>Terms &amp; Conditions</h3>
        <p>The information provided on this website has been compiled for your convenience. Seafood Safety Leaders makes no warranties about the accuracy or completeness of any information contained on this website.</p>
        <p>Seafood Safety Leaders including its affiliates, officers, employees, agents, contractors, successors and assigns, will not accept any liability for any loss, damage or other injury resulting from its use.</p>
        <p>Links to other websites are provided for your convenience and Safety Leaders is not responsible for the information contained on those websites. The provision of a link to another website does not constitute an endorsement or approval of that website, or any products or services offered on that web site, by Seafood Safety Leaders. Seafood Safety Leaders will not accept any liability for the use of those links to connect to web sites that are not under our control.</p>
        <p>Any third party site may link to this website provided that such a link is directed at the home page of this website. It is expressly prohibited for any person, business, entity or website to link to any page other than the home page of this website, without the prior written approval of Safety Safety Leaders </p>
        <p>It is expressly prohibited for any person, business, entity, or website to frame any page on this website, including the home page, in any way whatsoever, without the prior written approval of Seafood Leaders. Incorporating parts of this website into sites of parties not part of Seafood Safety Leaders is equally prohibited.</p>
        <p>Seafood Safety Leaders holds the copyright to this website.</p>
        <p>Seafood Safety Leaders regards your privacy as important and will take all reasonable steps to ensure that if you have entered details your email address and phone number will remain confidential. </p>
        <p>Seafood Safety Leaders reserves the right to make changes, modifications, additions, deletions and corrections to the website, at any time and without notice.</p>
        <p>Persons accessing the Seafood Safety Leaders website for any reason whatsoever, subject themselves to and agree to the terms and conditions of Seafood Safety Leaders as set out.</p>
        <p>This website does not collect your personal information, other than information you choose to provide through online forms or email addresses. We may use this to respond to you and discuss your enquiry.</p>
        <p>We will not disclose to a third party any personal information you provide without your consent, unless authorised by law. In the event of an investigation into unlawful activity (such as hacking or abusive emails), a law enforcement or government agency may exercise its legal authority to inspect web server records.</p>
        <p>We analyse how our site is used to help us make it better. We do this by obtaining reports on usage from Google Analytics. These tell us which pages are visited, for how long, on what operating systems and browsers and from what areas. This works by using a 'cookie'. Cookies are small files placed on your computer to help remember your preferences. You can find more information about cookies at Cookie Central.</p>
        <p>We do not use this information to identify you and do not obtain your IP address. However, you can choose not to have your usage recorded by disabling cookies on your browser or using Google Analytics' opt-out tool.</p>
        <p>All persons placing their first name and surname initial or business name are eligible to be entered into the monthly prize draw.</p>
        <p>Our employees are required to protect any personal information we collect. Our technology and work policies are designed to protect this information. However, if you send information over the Internet it may not be completely secure. If you are concerned about sending us sensitive information you might prefer to contact us by telephone or mail.</p>
        <p>The winners will be chosen by random generator and on the last day of each month for the valid time period.</p>
        <p>The winner will have the choice of which prize to choose until the number of prizes in that category have expired.</p>
        <p>The winner will be contacted using the contact details submitted for the safety leaders board. The winners will be notified by email or text message within three (3) business days of the drawing.  </p>
        <p>Prizes will be allocated until stock is exhausted.</p>
        <p>The entry period is from day month year – to day month year</p>
        <p>There is no cost or fee to enter the Promotion.</p>
      </div>
    </div>
    
    <!-- modal -->
    <!-- <div class="modal" v-if="!showModal">
      <button class="close" @click="showModal = false">x</button>
      <h3>Title</h3>
      <p>Description</p>
    </div> -->



    <div class="spacer s30"></div>

    <div v-if="!showForm" class="solutions">
      <div class="buttons">
        <span @click="showResourcesForm()" class="button is-primary">Become a Prize Sponsor</span>
      </div>
    </div>

    <transition name="fade">

      <div v-if="showForm" id="sec-signup" class="signup">

        <div class="icon">
          <img src="../assets/img/trophy.png" alt="resources" />
        </div>

        <div v-if="!formSubmitted">

          <h2>Donate a prize to the Seafood Leaders weekly draw.</h2>

          <h5>Fill in your details below and we will be in touch with you.</h5>

          <div class="join-form">

            <input type="text" v-model="responses.name" placeholder="Type your name (or business)...">

            <input type="email" v-model="responses.email" placeholder="Type your email address...">

            <input type="text" v-model="responses.phone" placeholder="Type your phone number...">

            <input type="text" v-model="responses.value" placeholder="Retail value of your prize...">

            <textarea rows="6" v-model="responses.details" placeholder="Details of the prize/s that you would like to donate.."></textarea>

            <!-- <div class="select">
              <select class="minimalx" v-model="responses.state">
                <option disabled value="">Which state are you in?</option>
                <option v-for="state in allstates" :key="state.name">
                  {{ state.name }}
                </option>
              </select>
            </div>

            <div class="spacer s10"></div>

            <div class="select">
              <select class="minimalx" v-model="responses.employs">
                <option disabled value="">Do you employ anyone else?</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div> -->

            <!-- <input type="email" v-model="responses.states" placeholder="Which states do you service?"> -->

            <div class="spacer s20"></div>

            <!-- <div class="uploaded-image" v-if="uploadedImage">
              <img :src="uploadedImage" alt="Photo" />
            </div>

            <div class="upload-wrapper">
              <image-uploader
                :preview="false"
                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                capture="environment"
                :debug="1" 
                doNotResize="['gif', 'svg']" 
                id="fileInput"
                :autoRotate="true"
                outputFormat="verbose"
                @input="setImage"
                :quality="0.7"
                accept="image/*"
              >
                <label for="fileInput" slot="upload-label">
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <path
                        class="path1"
                        d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                      ></path>
                    </svg>
                  </figure>
                  <span class="upload-caption">{{
                    hasImage ? "Change photo" : "Upload a photo"
                  }}</span>
                  <div class="clear"></div>
                </label>
              </image-uploader>
            </div>

            <div class="spacer s10"></div>

            <div v-if="hasImage" class="button-wrapper">
              <span @click="removeImage" class="button">Remove</span>
            </div>


             -->

            <div class="spacer s30"></div>

            <div class="buttons">
              <button :disabled="canSubmit" class="button is-primary" @click="submitForm()">Submit prize offer</button>
            </div>

            <div class="clear"></div>
            
          </div>

        </div>


        <div v-if="formSubmitted || submittingForm" class="">
          <h2 class="white"><span v-if="submittingForm"><font-awesome-icon icon="spinner" class="fa-spin 3x" />&nbsp;</span>{{submitMessage}}</h2>
        </div>

        <h2 v-if="errorMessage != ''" class="orange"><span><font-awesome-icon icon="exclamation-triangle" />&nbsp;</span>{{errorMessage}}</h2>

        <div class="clear"></div>
        
      </div>

    </transition>


    <div class="spacer s30"></div>

    <div class="centered">
      <span class="button is-primary" @click="showModal = true">Prize Terms &amp; Conditions</span>
    </div>

   
    <div class="spacer s40"></div>


    <div class="footer">
      <h5>Proudly sponsored by</h5>
      <ul>
        <!-- <li><a href="https://www.frdc.com.au/" target="_blank"><img src="../assets/img/logo-frdc-dark.png" alt="frdc" /></a></li> -->
        <li><a href="http://www.sesafe.com.au/" target="_blank"><img src="../assets/img/logo-sesafe-dark.png" alt="sesafe" /></a></li>
        <li><a href="http://fishsafeaustralia.com.au/" target="_blank"><img src="../assets/img/logo-fishsafe-dark.png" alt="fish safe" /></a></li>
      </ul>
    </div>

    


    <div class="spacer s100"></div>

    



    

  </div>
  
</template>


<script>
   
import ImageUploader from 'vue-image-upload-resize';
import { db } from '../main.js';  
import { getFirestore, addDoc, collection } from "firebase/firestore";


export default {

  name: 'PrizePool',
  components: {
    // ImageUploader,
  },

  computed: {
    canSubmit() {
      return this.responses.name == '' || this.responses.email == '' || this.responses.details == '';
    }
  },

  data: function() {
    return {
      resources: [
        {title: 'A vessel Safety Management System worth $149', sponsor: 'OffshoreSMS', image: 'offshoresms-app.jpg', details: 'Get a free safety management system for your commercial vessel worth $149. Includes a free 12 month full-access subscription to the OffshoreSMS app.', url: 'https://offshoresms.com.au', link: 'www.offshoresms.com.au' },
        {title: 'Access to 12 SeSafe Safety Training Modules', sponsor: 'SeSafe Safety Modules', image: 'sesafe.jpg', details: 'Access to 12 of the best safety training modules on the SeSafe learning system.', url: 'http://www.sesafe.com.au/', link: 'www.sesafe.com.au' },
        {title: '$100 Cash', sponsor: 'FishSafe Australia', image: 'fishsafe.jpg', details: 'Get a $100 cash gift to spend whenever and wherever you like!', url: 'http://fishsafeaustralia.com.au/', link: 'www.fishsafeaustralia.com.au' },
      ],
      showForm: false,
      responses: {
        name: '',
        email: '',
        phone: '',
        value: '',
        details: '',
      },
      allstates: [
        { name: "Tasmania" },
        { name: "Victoria" },
        { name: "New South Wales" },
        { name: "Queensland" },
        { name: "South Australia" },
        { name: "Western Australia" },
        { name: "Northern Territory" }
      ],

      hasImage: false,
      imageURL: "",
      uploadedImage: "",
      submitMessage: 'Submitting your offer...',
      errorMessage: '',
      formSubmitted: false,
      submittingForm: false,
      showModal: false,

    }
  },

  methods: {
    
    showResourcesForm() {
      this.showForm = true;
    },

    async submitForm() {
      let self = this;
      console.log('submitted');
      this.submittingForm = true;
      this.formSubmitted = true;
      this.errorMessage = '';
      try {
        const docRef = await addDoc(collection(db, "seafoodLeaders","prizes","formSubmissions"), {
          name: self.responses.name,
          email: self.responses.email,
          phone: self.responses.phone,
          value: self.responses.value,
          details: self.responses.details,
          // imageURL: self.imageURL ? self.imageURL : ''
        });
        console.log("Document added with ID: ", docRef.id);
        setTimeout(() => {
          this.submitMessage = 'Thanks for your generous offer. We will be in touch with you shortly.';
          this.submittingForm = false;
        }, 2000);
      } catch (e) {
        console.error("Error adding document: ", e);
        this.errorMessage = 'Something went wrong, sorry. Please refresh the page and try again.';
        this.formSubmitted = false;
        this.submittingForm = false;
      }

    },

    setImage: function(output) {
      this.hasImage = true;
      this.imageURL = output.dataUrl;
      this.uploadedImage = output.dataUrl;
    },

    removeImage: function() {
      this.hasImage = false;
      this.uploadedImage = "";
      this.imageURL = '';
    },



  }


}





</script>


<style lang="scss" scoped>

  .main {
    // background: url('../assets/img/lines1.png') repeat 0 0;
  }

  .path1 {
    fill: #fff;
  }

  .hero-wrapper {
    width: 100%;
    height: 550px;
    height: 55vh;
    max-height: 926px;
    background-color: #040d21;
    background-color: #003f56;
    background: url('../assets/img/fishing-boat-bg.jpg') no-repeat center bottom;
    position: relative;
    overflow: hidden;
    background-size: cover;
    // .hero-glow {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   background: url('../assets/img/hero-glow.svg') no-repeat 60% 60%;
    //   width: 100%;
    //   height: 100%;
    //   .stars {
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     // background: url('../assets/img/stars3.gif') no-repeat center center;
    //     background: url('../assets/img/stars3.gif') no-repeat center center;
    //     background-size: contain;
    //     opacity: 0.2;
    //   }
    // }
    .curved-border {
      // border-radius: 60%/50px;
      border-radius: 60% 60% 0 0;
      background: white;
      position: absolute;
      bottom: -30px;
      left: 0;
      height: 60px;
      width: 100%;
    }
    .hero-content {
      width: 90%;
      max-width: 800px;
      position: absolute;
      top: 50%;
      left: 2%;
      transform: translateY(-50%);
      text-align: left;
      padding: 2rem;
      h4 {
        margin: 0;
        font-size: 1.8rem;
        color: #fff;
      }
      h1 {
        color: yellow;
        font-size: 5rem;
        line-height: 4.7rem;
        margin-bottom: 20px;
      }
      h5 {
        font-size: 1.3rem;
        line-height: 1.7rem;
        color: #fff;
      }
    }
    // .ivan {
    //   position: absolute;
    //   bottom: -40px;
    //   right: 0;
    //   background: url('../assets/img/ivan.png');
    //   width: 400px;
    //   height: 410px;
    // }
  }

  .button {
    .sub-text {
      font-size: 1rem;
      margin-top: 5px;
      font-style: italic;
    }
  }


  #sec-wall {
    padding: 0 2rem;
    h2 {
      font-family: 'Work Sans', sans-serif;
      font-weight: 900;
      font-size: 3rem;
      color: deepskyblue;
      margin: 20px 0;
    }
    h5 {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }
  }

  .resources-wrapper {
    margin: 3rem 2rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #e4e4e4;
    .resource {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr;
      max-width: 1000px;
      margin: 0 auto 3rem;
      .image {
        img {
          border-radius: 5px;
        }
      }
      .content {
        text-align: left;
        h4 {
          color: #05abe3;
          font-size: 1.5rem;
          margin-bottom: 4px;
          span {
            font-size: 1.4rem;
            vertical-align: text-top;
          }
        }
        .details {
          font-size: 1.1rem;
          margin-bottom: 4px;
        }
        .url {
          span {
            color: #05abe3;
            font-size: 1.2rem;
          }
        }
      }
    }
  }


  .signup {
    width: 95%;
    max-width: 900px;
    margin: 0 auto;
    background: #4c73a7;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(#11598c, a), to(#0b3773));
    background-image: linear-gradient(a #11598c, #0b3773);
    padding: 2rem;
    border-radius: 12px;
    color: deepskyblue;
    text-align: center;
    .icon {
      img {
         width: 135px;
      }
    }
    h2 {
      color: #ffff48;
      margin-top: 0;
    }
    h5 {
      color: #fff;
    }
    .join-form {
      margin: 2rem 0;
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea {
        font-family: 'Arial', sans-serif;
        font-weight: 400;
        display: block;
        margin: 10px auto;
        border: none;
        padding: 20px;
        color: dodgerblue;
        width: 100%;
        max-width: 500px;
        box-sizing: border-box;
        font-size: 1.1rem;
        border-radius: 3px;
        &:active {
          outline: none;
          box-shadow: none;
          border: none;
        }
        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }
        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: none;
        }   
        textarea {
          padding: 20px 15px;
        }   
      }        
    }
  }

  .uploaded-image {
    img {
      border-radius: 5px;
    }
  }

  

  .footer {
    margin: 3rem auto;
    padding-top: 2rem;
    border-top: 1px solid #e4e4e4;
    width: 95%;
    max-width: 1100px;
    ul {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        color: #fff;
        padding: 10px 5px;
        img {
          height: 40px;
        }
      }
    }
  }



  .overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    overflow: scroll;
    .modal {
      position: relative;
      width: 90%;
      max-width: 800px;
      z-index: 9999;
      margin: 5rem auto;
      padding: 25px 40px;
      background-color: #fff;
      border-radius: 5px;
      .close{
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        border: none;
        /* padding: 15px; */
        width: 30px;
        height: 30px;
        text-align: center;
        /* display: flex; */
        font-size: 1rem;
        line-height: 0;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          background: #f87a7a;
          color: #fff;
        }
      }
    }
  }

    // .modal {
    //   position: relative;
    //   width: 300px;
    //   z-index: 9999;
    //   margin: 0 auto;
    //   padding: 20px 30px;
    //   background-color: #fff;
    //   .close{
    //     position: absolute;
    //     top: 10px;
    //     right: 10px;
    //   }
    // }

    





  @media only screen and (max-width: 959px) {

    

  }



  @media only screen and (max-width: 767px) {

    .hero-wrapper {
      height: auto;
      max-height: none;
      .hero-glow {
        background: none;
        position: relative;
        transform: none;
        left: 0;
      }
      .hero-header {
        position: relative;
      }
      .hero-content {
        position: relative;
        width: 100%;
        height: auto;
        transform: none;
        top: auto;
        margin-top: 10%;
        margin-bottom: 5rem;
        padding: 1rem;
        h1 {
          font-size: 4rem;
          line-height: 3.5rem;
        }
      }
      .ivan {
        display: none;
      }
    }
    


  }


    
  @media only screen and (max-width: 479px) {

    .hero-wrapper {
      .hero-content {
        h1 {
          font-size: 3rem;
          line-height: 2.8rem;
        }
      }
      .ivan {
        display: none;
      }
    }

    .resources-wrapper {
      .resource {
        grid-template-columns: 1fr;
        .image {

        }
        .content {
          h4 {

          }
          .details {

          }
          .url {

          }
        }
      }
    }
    


  }

    



</style>







