<template>

  <div class="main">

    <div class="hero-wrapper">

      <div class="hero-glow">

        <!-- <div class="stars"></div> -->

        <div class="hero-header">
          <ul>
            <!-- <li class="hide479"><a href="https://www.frdc.com.au/" target="_blank"><img src="../assets/img/logo-frdc.png" alt="frdc" /></a></li> -->
            <li class="hide479"><a href="http://www.sesafe.com.au/" target="_blank"><img src="../assets/img/logo-sesafe.png" alt="sesafe" /></a></li>
            <li class="hide479"><a href="http://fishsafeaustralia.com.au/" target="_blank"><img src="../assets/img/logo-fishsafe.png" alt="fish safe" /></a></li>
          </ul>
          <router-link to="/" class="home-icon"><font-awesome-icon icon="home" /></router-link>
        </div>

        <div class="hero-content">
          <h4>Some useful</h4>
          <h1>Safety Resources</h1>

          <h5>A collection of useful safety resources for any commercial fishing operation.</h5>

          <div class="spacer s20"></div>


          <!-- <div class="hero-form">
            <div class="buttons">
              <a href="#sec-signup" v-smooth-scroll class="button is-primary">Count me in</a>
              <a href="#sec-wall" v-smooth-scroll class="button is-white">See the Industry Wall</a>
            </div>
          </div> -->

        </div>

        <!-- <div class="ivan"></div> -->
        
      </div>

      <div class="curved-border"></div>
      
    </div>


    <div class="resources-wrapper">
      
      <div class="resource" v-for="(item, index) in resources" :key="index">
        
          <div class="image"><a :href="item.url" target="_blank"><img :src="require(`../assets/img/logos/${item.image}`)" :alt="item.title" /></a></div>
          <div class="content">
            <a :href="item.url" target="_blank">
              <h4>{{item.title}}</h4>
              <div class="details">{{item.details}}</div>
              <div class="details">{{item.detailsPartTwo}}</div>
              <div class="url">{{item.link}}</div>
            </a>
          </div>
      </div>

    </div>

    <div class="spacer s30"></div>

    <div v-if="!showForm" class="solutions">
      <div class="buttons">
        <span @click="showResourcesForm()" class="button is-primary">Add your business to the list of resources</span>
      </div>
    </div>

    <transition name="fade">

      <div v-if="showForm" id="sec-signup" class="signup">

        <div class="icon">
          <img src="../assets/img/target.png" alt="resources" />
        </div>

        <div v-if="!formSubmitted">

          <h2>Share your safety resources here.</h2>

          <h5>Add your business or organisation name to the list of safety resources for Australian commercial fishermen.</h5>

          <div class="join-form">

            <input type="text" v-model="responses.name" placeholder="Type your name (or business)...">

            <input type="email" v-model="responses.email" placeholder="Type your email address...">

            <input type="text" v-model="responses.phone" placeholder="Type your phone number...">

            <input type="text" v-model="responses.website" placeholder="Enter your website address...">

            <!-- <input type="text" v-model="responses.details" placeholder="Details of your resource offering.."> -->

            <textarea rows="6" v-model="responses.details" placeholder="Details of safety resources that you offer.."></textarea>

            <!-- <div class="select">
              <select class="minimalx" v-model="responses.state">
                <option disabled value="">Which state are you in?</option>
                <option v-for="state in allstates" :key="state.name">
                  {{ state.name }}
                </option>
              </select>
            </div>

            <div class="spacer s10"></div>

            <div class="select">
              <select class="minimalx" v-model="responses.employs">
                <option disabled value="">Do you employ anyone else?</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div> -->

            <input type="email" v-model="responses.states" placeholder="Which states do you service?">

            <div class="spacer s20"></div>

            <div class="uploaded-image" v-if="uploadedImage">
              <img :src="uploadedImage" alt="Photo" />
            </div>

            <div class="upload-wrapper">
              <image-uploader
                :preview="false"
                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                capture="environment"
                :debug="1" 
                doNotResize="['gif', 'svg']" 
                id="fileInput"
                :autoRotate="true"
                outputFormat="verbose"
                @input="setImage"
                :quality="0.7"
                accept="image/*"
              >
                <label for="fileInput" slot="upload-label">
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <path
                        class="path1"
                        d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                      ></path>
                    </svg>
                  </figure>
                  <span class="upload-caption">{{
                    hasImage ? "Change photo" : "Upload a photo"
                  }}</span>
                  <div class="clear"></div>
                </label>
              </image-uploader>
            </div>

            <div class="spacer s10"></div>

            <div v-if="hasImage" class="button-wrapper">
              <span @click="removeImage" class="button">Remove</span>
            </div>


            <div class="spacer s30"></div>

            <div class="buttons">
              <button :disabled="canSubmit" class="button is-primary" @click="submitForm()">Add to Resources</button>
            </div>

            <div class="clear"></div>
            
          </div>

        </div>


        <div v-if="formSubmitted || submittingForm" class="">
          <h2 class="white"><span v-if="submittingForm"><font-awesome-icon icon="spinner" class="fa-spin 3x" />&nbsp;</span>{{submitMessage}}</h2>
        </div>

        <h2 v-if="errorMessage != ''" class="orange"><span><font-awesome-icon icon="exclamation-triangle" />&nbsp;</span>{{errorMessage}}</h2>

        <div class="clear"></div>
        
      </div>

    </transition>

   
    <div class="spacer s40"></div>


    <div class="footer">
      <h5>Proudly sponsored by</h5>
      <ul>
        <!-- <li><a href="https://www.frdc.com.au/" target="_blank"><img src="../assets/img/logo-frdc-dark.png" alt="frdc" /></a></li> -->
        <li><a href="http://www.sesafe.com.au/" target="_blank"><img src="../assets/img/logo-sesafe-dark.png" alt="sesafe" /></a></li>
        <li><a href="http://fishsafeaustralia.com.au/" target="_blank"><img src="../assets/img/logo-fishsafe-dark.png" alt="fish safe" /></a></li>
      </ul>
    </div>

    


    <div class="spacer s100"></div>

    

  </div>
  
</template>


<script>
   
import ImageUploader from 'vue-image-upload-resize';
import { db } from '../main.js';  
import { getFirestore, addDoc, collection } from "firebase/firestore";


export default {

  name: 'SafetySolutions',
  components: {
    ImageUploader,
  },

  computed: {
    canSubmit() {
      return this.responses.name == '' || this.responses.email == '' || this.responses.details == '';
    }
  },

  data: function() {
    return {
      resources: [
        {title: 'SeSafe Safety Modules', state: 'Australia Wide', image: 'sesafe.jpg', details: 'The goal of the SeSafe project is to raise awareness and improve safety performance in the Australian fishing and aquaculture industry.', detailsPartTwo: 'SeSAFE is sponsored by the Fisheries Research and Development Corporation (FRDC), the Australian commercial fishing industry, and the Australian Maritime Safety Authority (AMSA).', url: 'http://www.sesafe.com.au/', link: 'www.sesafe.com.au' },
        {title: 'FishSafe Australia', state: 'Australia Wide', image: 'fishsafe.jpg', details: 'A one stop shop for Australia\'s comercial fishing industry to access health and safety resources', detailsPartTwo: 'Fishsafe Australia is sponsored by the Fisheries Research and Development Corporation (FRDC).', url: 'http://fishsafeaustralia.com.au/', link: 'www.fishsafeaustralia.com.au' },
        {title: 'OffshoreSMS', state: 'Australia Wide', image: 'offshoresms-app.jpg', details: 'An Australian safety app for commercial vessel owners, skippers and crew. Create a safety management system for your vessel, log maintenance, startups and crew inductions, record sea time or manage your entire vessel fleet.', detailsPartTwo: '', url: 'https://offshoresms.com.au', link: 'www.offshoresms.com.au' },
      ],
      showForm: false,
      responses: {
        name: '',
        email: '',
        phone: '',
        website: '',
        details: '',
        states: '',
      },
      allstates: [
        { name: "Tasmania" },
        { name: "Victoria" },
        { name: "New South Wales" },
        { name: "Queensland" },
        { name: "South Australia" },
        { name: "Western Australia" },
        { name: "Northern Territory" }
      ],

      hasImage: false,
      imageURL: "",
      uploadedImage: "",
      submitMessage: 'Submitting your details...',
      errorMessage: '',
      formSubmitted: false,
      submittingForm: false,

    }
  },

  methods: {
    
    showResourcesForm() {
      this.showForm = true;
    },

    async submitForm() {
      let self = this;
      console.log('submitted');
      this.submittingForm = true;
      this.formSubmitted = true;
      this.errorMessage = '';
      try {
        const docRef = await addDoc(collection(db, "seafoodLeaders","resources","formSubmissions"), {
          name: self.responses.name,
          email: self.responses.email,
          phone: self.responses.phone,
          website: self.responses.website,
          details: self.responses.details,
          states: self.responses.states,
          imageURL: self.imageURL ? self.imageURL : ''
        });
        console.log("Document added with ID: ", docRef.id);
        setTimeout(() => {
          this.submitMessage = 'Thanks for adding your safety resource. We will be in touch if we have any questions.';
          this.submittingForm = false;
        }, 2000);
      } catch (e) {
        console.error("Error adding document: ", e);
        this.errorMessage = 'Something went wrong, sorry. Please refresh the page and try again.';
        this.formSubmitted = false;
        this.submittingForm = false;
      }

    },

    setImage: function(output) {
      this.hasImage = true;
      this.imageURL = output.dataUrl;
      this.uploadedImage = output.dataUrl;
    },

    removeImage: function() {
      this.hasImage = false;
      this.uploadedImage = "";
      this.imageURL = '';
    },



  }


}





</script>


<style lang="scss" scoped>

  .main {
    // background: url('../assets/img/lines1.png') repeat 0 0;
  }

  .path1 {
    fill: #fff;
  }

  .hero-wrapper {
    width: 100%;
    height: 550px;
    height: 55vh;
    max-height: 926px;
    background-color: #040d21;
    background-color: #003f56;
    background: url('../assets/img/fishing-bg-1.jpg') no-repeat 80% center;
    position: relative;
    overflow: hidden;
    background-size: cover;
    // .hero-glow {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   background: url('../assets/img/hero-glow.svg') no-repeat 60% 60%;
    //   width: 100%;
    //   height: 100%;
    //   .stars {
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     // background: url('../assets/img/stars3.gif') no-repeat center center;
    //     background: url('../assets/img/stars3.gif') no-repeat center center;
    //     background-size: contain;
    //     opacity: 0.2;
    //   }
    // }
    // .hero-header {
    //   position: absolute;
    //   top: 10px;
    //   right: 30px;
    //   ul {
    //     list-style: none;
    //     display: inline-block;
    //     vertical-align: middle;
    //     li {
    //       display: inline-block;
    //       color: #fff;
    //       padding: 10px 5px;
    //       img {
    //         height: 40px;
    //       }
    //     }
    //   }
    //   .home-icon {
    //     display: inline-block;
    //     height: 50px;
    //     color: #fff;
    //     vertical-align: middle;
    //     font-size: 1.5rem;
    //     padding-top: 10px;
    //     margin-left: 13px;
    //   }
    // }
    .curved-border {
      // border-radius: 60%/50px;
      border-radius: 60% 60% 0 0;
      background: white;
      position: absolute;
      bottom: -30px;
      left: 0;
      height: 60px;
      width: 100%;
    }
    .hero-content {
      width: 50%;
      max-width: 800px;
      position: absolute;
      top: 50%;
      left: 2%;
      transform: translateY(-50%);
      text-align: left;
      padding: 2rem;
      h4 {
        margin: 0;
        font-size: 1.8rem;
        color: #fff;
      }
      h1 {
        color: deepskyblue;
        font-size: 5rem;
        line-height: 4.7rem;
        margin-bottom: 20px;
      }
      h5 {
        font-size: 1.3rem;
        line-height: 1.7rem;
        color: #fff;
      }
    }
    // .ivan {
    //   position: absolute;
    //   bottom: -40px;
    //   right: 0;
    //   background: url('../assets/img/ivan.png');
    //   width: 400px;
    //   height: 410px;
    // }
  }

  .button {
    .sub-text {
      font-size: 1rem;
      margin-top: 5px;
      font-style: italic;
    }
  }


  .resources-wrapper {
    margin: 3rem 1rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #e4e4e4;
    .resource {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 3fr;
      max-width: 1000px;
      margin: 0 auto 3rem;
      .image {
        img {
          border-radius: 5px;
        }
      }
      .content {
        text-align: left;
        h4 {
          color: #05abe3;
          font-size: 1.5rem;
          margin-bottom: 4px;
        }
        .details {
          font-size: 1.1rem;
          margin-bottom: 4px;
          color: #333;
        }
        .url {
          color: #05abe3;
          font-size: 1.2rem;
        }
      }
    }
  }


  .signup {
    width: 95%;
    max-width: 900px;
    margin: 0 auto;
    background: #4c73a7;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(#11598c, a), to(#0b3773));
    background-image: linear-gradient(a #11598c, #0b3773);
    padding: 2rem;
    border-radius: 12px;
    color: deepskyblue;
    text-align: center;
    .icon {
      img {
         width: 135px;
      }
    }
    h2 {
      color: #ffff48;
      margin-top: 0;
    }
    h5 {
      color: #fff;
    }
    .join-form {
      margin: 2rem 0;
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea {
        font-family: 'Arial', sans-serif;
        font-weight: 400;
        display: block;
        margin: 10px auto;
        border: none;
        padding: 20px;
        color: dodgerblue;
        width: 100%;
        max-width: 500px;
        box-sizing: border-box;
        font-size: 1.1rem;
        border-radius: 3px;
        &:active {
          outline: none;
          box-shadow: none;
          border: none;
        }
        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }
        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: none;
        }
      }       
      textarea {
        padding: 20px 15px;
      }       
    }
  }

  .uploaded-image {
    img {
      border-radius: 5px;
    }
  }

  

  .footer {
    margin: 3rem auto;
    padding-top: 2rem;
    border-top: 1px solid #e4e4e4;
    width: 95%;
    max-width: 1100px;
    ul {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        color: #fff;
        padding: 10px 5px;
        img {
          height: 40px;
        }
      }
    }
  }


  @media only screen and (max-width: 959px) {

    

  }



  @media only screen and (max-width: 767px) {

    .hero-wrapper {
      height: auto;
      max-height: none;
      .hero-glow {
        background: none;
        position: relative;
        transform: none;
        left: 0;
      }
      .hero-header {
        position: relative;
      }
      .hero-content {
        position: relative;
        width: 100%;
        height: auto;
        transform: none;
        top: auto;
        margin-top: 10%;
        margin-bottom: 5rem;
        padding: 1rem;
        h1 {
          font-size: 4rem;
          line-height: 3.5rem;
        }
      }
      .ivan {
        display: none;
      }
    }
    


  }


    
  @media only screen and (max-width: 479px) {

    .hero-wrapper {
      .hero-content {
        h1 {
          font-size: 3rem;
          line-height: 2.8rem;
        }
      }
      .ivan {
        display: none;
      }
    }

    .resources-wrapper {
      .resource {
        grid-template-columns: 1fr;
        .image {

        }
        .content {
          h4 {

          }
          .details {

          }
          .url {

          }
        }
      }
    }
    


  }

    



</style>







